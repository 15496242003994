import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../../components/BackgroundImage";
import HeaderBar from "../../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../../components/Modal";
import { withI18n, translate } from "../../libs/withI18n";
import FileSaver from "file-saver";
import * as NQRCode from "qrcode";
import { handleError } from "../../libs/errors";
import { ShimmerThumbnail } from "react-shimmer-effects";
import lodash from "lodash";
import { LoadingOverlay } from "../../components/Overlay";
import {
  showLoadingSpinner,
  hideLoadingSpinner,
  addNotification,
} from "../../App";
import { CopyToClipboard } from "react-copy-to-clipboard";
import dayjs from "dayjs";

let urlValues;

var MONTHS = [
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Insidental",
];

class StudentBillAndPaymentNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listSiswa: [],
      loading: false,
      selectedSiswa: null,
      listTagihan: null,
      showFilter: false,
      height: 0,
      selectedBills: [],

      listTahunAjaran: [],

      filterCriteria: {
        studentId: "",
        academicYear: "",
        type: "",
      },
      previousTransaction: null,
      showPreviousTransactionModal: false,
      vaCopied: false,

      checkOutModal: {
        visible: false,
        total: 0,
        fee: 0,
        totalBill: 0,
      },
      paymentChannelModalVisible: false,
      paymentMethod: "",

      successCheckOutModal: {
        visible: false,
        vaNumber: "",
        bankCode: "",
      },

      qrisObject: {
        qrisModalVisible: false,
      },
      selectedTagihanBulan: [],
      paymentChannels: [],
      isPaymentH2H: false,
    };
    this.qrcode = React.createRef();
  }

  PAYMENT_METHODS = [
    {
      name: "linkAja",
      label: "LINK AJA",
      type: "ewallet",
    },
    {
      name: "tokopedia",
      label: "TOKOPEDIA",
      type: "ecommerce",
    },
    {
      name: "shopee",
      label: "SHOPEE",
      type: "ecommerce",
    },
    // {
    //   name: "ovo",
    //   label: "OVO",
    //   icon: "/static/images/OVO.png",
    // },
    // {
    //   name: "dana",
    //   label: "DANA",
    //   icon: "/static/images/dana.png",
    // },
    {
      name: "bca",
      label: "BANK BCA",
      type: "va",
    },
    {
      name: "mandiri",
      label: "BANK MANDIRI",
      type: "va",
    },
    {
      name: "bni",
      label: "BANK BNI",
      type: "va",
    },
    {
      name: "bri",
      label: "BANK BRI",
      type: "va",
    },
    {
      name: "bsi",
      label: "BANK BSI",
      type: "va",
    },
    {
      name: "permata",
      label: "BANK PERMATA",
      type: "va",
    },
    // {
    //   name: "qris",
    //   label: "QRIS",
    //
    //   type: "qris",
    //   display: this.props.router.query.PREFIX === "DEMO" ? "block" : "none",
    // },
    {
      name: "cimb",
      label: "BANK CIMB NIAGA",
      type: "va",
    },
    // {
    //   name: "btn",
    //   label: "BANK BTN",
    //   icon: "/static/images/BTN.png",
    //   type: "va",
    //   display: this.props.router.query.PREFIX === "DEMO" ? "block" : "none",
    // },
    // {
    //   name: "muamalat",
    //   label: "BANK MUAMALAT",
    //   icon: "/static/images/muamalat.png",
    //   type: "va",
    //   display: this.props.router.query.PREFIX === "DEMO" ? "block" : "none",
    // },
  ];

  componentWillReceiveProps = () => {
    this.componentDidMount();
  };

  componentDidMount = async () => {
    urlValues = queryString.parse(this.props.location.search);

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.setState({
      loading: true,
    });

    const res = await this.refetchData({
      sessionId: urlValues.sessionId,
      studentId: "",
      type: "",
      academicYear: "",
    });

    // console.log(res);

    this.setState({
      loading: false,
      listSiswa: res.listSiswa,
      selectedSiswa: res.selectedSiswa,
      listTagihan: res.listTagihan,
      filterCriteria: res.filterCriteria,
      listTahunAjaran: res.listTahunAjaran,
      paymentChannels: res.paymentChannels,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  resize() {
    if (this.divElement) {
      const height = this.divElement.clientHeight;
      this.setState({
        height,
      });
    }

    let currentHideNav = window.innerWidth <= 760;
    if (currentHideNav !== this.state.hideNav) {
      this.setState({ hideNav: currentHideNav });
    }
  }

  refetchData = async ({
    sessionId,
    studentId,
    type,
    academicYear,
    PREFIX,
  }) => {
    try {
      urlValues = queryString.parse(this.props.location.search);

      // console.log({
      //   sessionId,
      //   studentId,
      //   type,
      //   academicYear,
      //   PREFIX,
      // });

      const res = await mwvClient.query({
        query: ALL_STUDENT_QUERIES,
        variables: {
          _id: sessionId,
          PREFIX: PREFIX ? PREFIX : urlValues.PREFIX,
          studentId: studentId
            ? studentId
            : urlValues.studentId
            ? urlValues.studentId
            : "",
        },
        fetchPolicy: "no-cache",
      });

      const listTahunAjaran = res.data.allAcademicYears;

      // console.log("listTahunAjaran", listTahunAjaran);

      let selectedSiswa = null;
      if (!studentId) {
        selectedSiswa = res.data.allStudentBySessionId[0];
      } else {
        selectedSiswa = res.data.allStudentBySessionId.find(
          (siswa) => siswa._id === studentId
        );
      }

      let tahunAjaran = "";
      if (!academicYear) {
        const found = listTahunAjaran.find((ta) => ta.inProgress);
        tahunAjaran = found.label;
      } else {
        tahunAjaran = academicYear;
      }

      if (!tahunAjaran) {
        throw new Error("Invalid Academic Year.");
      }
      const resBill = await mwvClient.query({
        query: ALL_STUDENT_BILLS,
        variables: {
          sessionId,
          studentId: studentId ? studentId : selectedSiswa._id,
          type,
          academicYear: tahunAjaran,
        },
        fetchPolicy: "no-cache",
      });

      if (
        selectedSiswa.PREFIX === "DEMO" ||
        selectedSiswa.PREFIX === "SMAN1PURI"
      ) {
        MONTHS = [
          "Insidental",
          "Juli",
          "Agustus",
          "September",
          "Oktober",
          "November",
          "Desember",
          "Januari",
          "Februari",
          "Maret",
          "April",
          "Mei",
          "Juni",
        ];
      }

      let listTagihan = {};
      if (resBill.data.allStudentBillNew.length > 0) {
        const indexed = resBill.data.allStudentBillNew.reduce((all, b) => {
          if (!all[b.month]) {
            all[b.month] = [];
          }
          all[b.month].push(b);
          return all;
        }, {});

        for (const m of MONTHS) {
          if (indexed[m]) {
            listTagihan[m] = indexed[m];
          }
        }
      }
      return {
        listTagihan,
        selectedSiswa,
        listSiswa: res.data.allStudentBySessionId,
        listTahunAjaran,
        filterCriteria: {
          studentId,
          academicYear: tahunAjaran,
          type,
        },
        paymentChannels: res.data.h2hChecker,
      };
    } catch (err) {
      handleError(err);
    }
  };

  openFilter = async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();
    try {
      urlValues = queryString.parse(this.props.location.search);

      this.setState({
        loading: true,
      });
      const { filterCriteria } = this.state;
      // console.log(filterCriteria);
      const res = await this.refetchData({
        sessionId: urlValues.sessionId,
        studentId: filterCriteria.studentId,
        type: filterCriteria.type,
        academicYear: filterCriteria.academicYear,
      });

      this.setState({
        loading: false,
        showFilter: true,
        listSiswa: res.listSiswa,
        selectedSiswa: res.selectedSiswa,
        listTagihan: res.listTagihan,
        listTahunAjaran: res.listTahunAjaran,
        filterCriteria: {
          studentId: res.selectedSiswa._id,
          academicYear: filterCriteria.academicYear,
          type: "",
        },
      });
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  handleFilterCriteria = (key) => async (e) => {
    if (e) e.preventDefault();
    if (key === "studentId") {
      this.setState({
        filterCriteria: {
          ...this.state.filterCriteria,
          studentId: e.target.value,
        },
      });
      const resTA = await mwvClient.query({
        query: ALL_ACADEMIC_YEAR_BY_STUDENT,
        variables: {
          studentId: e.target.value,
        },
        fetchPolicy: "no-cache",
      });
      const tahunAjaranAktifDefault =
        resTA.data.allAcademicYearsByStudent.filter((ta) => ta.inProgress)[0]
          .label;
      this.setState({
        listTahunAjaran: resTA.data.allAcademicYearsByStudent,
        filterCriteria: {
          ...this.state.filterCriteria,
          academicYear: tahunAjaranAktifDefault,
        },
      });
    } else {
      this.setState({
        filterCriteria: {
          ...this.state.filterCriteria,
          [key]: e.target.value,
        },
      });
    }
  };

  submitFilter = async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();
    const { filterCriteria, listSiswa } = this.state;
    urlValues = queryString.parse(this.props.location.search);

    this.setState({
      loading: true,
    });
    try {
      let PREFIX = "";
      const found = listSiswa.find((s) => s._id === filterCriteria.studentId);
      if (found) {
        PREFIX = found.PREFIX;
      }

      if (
        PREFIX === "DEMO" &&
        filterCriteria.studentId === "12bfa1aa-5e2e-4d55-a86a-4ae5ea42389e"
      ) {
        let urlParams = {
          sessionId: urlValues.sessionId,
          studentId: filterCriteria.studentId,
          PREFIX,
        };
        urlParams = queryString.stringify(urlParams);
        window.location.href = `/student_bill/student_bill_new_v2?${urlParams}`;
        // Router.replace({
        //   pathname: "/tagihan_siswa_new_with_v2",
        //   query: {
        //     sessionId: router.query.sessionId,
        //     studentId: filterCriteria.studentId,
        //     PREFIX,
        //   },
        // });
      }

      const res = await this.refetchData({
        sessionId: urlValues.sessionId,
        studentId: filterCriteria.studentId,
        type: filterCriteria.type,
        academicYear: filterCriteria.academicYear,
        PREFIX,
      });

      this.setState({
        loading: false,
        showFilter: false,
        listSiswa: res.listSiswa,
        selectedSiswa: res.selectedSiswa,
        listTagihan: res.listTagihan,
        listTahunAjaran: res.listTahunAjaran,
        paymentChannels: res.paymentChannels,
        selectedBills: [],
      });
    } catch (err) {
      handleError(err);
    }

    hideLoadingSpinner();
  };

  handleSelectBill = (bill) => (e) => {
    if (e) e.preventDefault();

    if (
      bill.PREFIX === "RAHMATANSCHOOL" ||
      bill.PREFIX === "STARLAND" ||
      bill.PREFIX === "SABILALMUTAQIN" ||
      bill.PREFIX === "SDLABUMMALANG" ||
      bill.PREFIX === "SDLABUMBLITAR" ||
      bill.PREFIX === "TKLABUMBLITAR"
    ) {
      return;
    }

    if (bill.PREFIX === "INKA") {
      const { listTagihan } = this.state;
      const tmp = listTagihan[bill.month];
      if (tmp.length > 0) {
        if (this.state.selectedBills.length === 0) {
          this.setState({
            selectedBills: tmp.filter(
              (t) => t.status === "BELUM LUNAS" && t.month === bill.month
            ),
          });
        } else {
          const findIndex = this.state.selectedBills
            .map((b) => b.month)
            .findIndex((bi) => bi === bill.month);

          if (findIndex > -1) {
            this.setState({
              selectedBills: this.state.selectedBills.filter(
                (b) => b.month !== bill.month
              ),
            });
          } else {
            this.setState({
              selectedBills: [
                ...this.state.selectedBills,
                ...tmp.filter(
                  (t) => t.status === "BELUM LUNAS" && t.month === bill.month
                ),
              ],
            });
          }
        }
      }
    } else {
      if (this.state.selectedBills.length === 0) {
        this.setState({
          selectedBills: [bill],
        });
      } else {
        const findIndex = this.state.selectedBills
          .map((b) => b._id)
          .findIndex((bi) => bi === bill._id);

        if (findIndex > -1) {
          this.setState({
            selectedBills: this.state.selectedBills.filter(
              (b) => b._id !== bill._id
            ),
          });
        } else {
          this.setState({
            selectedBills: [...this.state.selectedBills, bill],
          });
        }
      }
    }
  };

  openHistory = (e) => {
    if (e) e.preventDefault();
    urlValues = queryString.parse(this.props.location.search);
    const { router } = this.props;
    const tahunAjaran = this.state.listTahunAjaran.find((ta) => ta.inProgress);

    let urlParams = {
      ...urlValues,
      studentId: this.state.selectedSiswa._id,
      academicYear: tahunAjaran.label,
    };
    urlParams = queryString.stringify(urlParams);
    window.location.href = `/student_bill/school_pay_history_new?${urlParams}`;
  };

  openCheckOut = (selectedBills) => async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();

    try {
      urlValues = queryString.parse(this.props.location.search);

      if (!this.state.paymentMethod) {
        throw {
          message: "Metode Pembayaran Belum Dipilih!",
        };
      }

      const result = await mwvClient.mutate({
        mutation: CHECK_PREVIOUS_TRANSACTION,
        variables: {
          sessionId: urlValues.sessionId,
          studentId: selectedBills[0].studentId,
        },
        fetchPolicy: "no-cache",
      });

      if (result.data.checkPreviousTransaction) {
        const VAPaymentDetail = JSON.parse(
          result.data.checkPreviousTransaction.VAPaymentDetail
        );

        let virtualAccount = "";
        let checkOutUrl = "";
        if (VAPaymentDetail.provider === "MCP") {
          if (
            VAPaymentDetail.mcpVersion &&
            VAPaymentDetail.mcpVersion === "v2"
          ) {
            virtualAccount = VAPaymentDetail.payment_details.va_number;
          } else {
            virtualAccount = VAPaymentDetail.payment_code;
          }
        } else if (VAPaymentDetail.provider === "XENDIT") {
          virtualAccount = VAPaymentDetail.account_number;
        } else if (VAPaymentDetail.provider === "FASPAY") {
          if (VAPaymentDetail.bankCode === "BCA") {
            virtualAccount = VAPaymentDetail.va_number;
          } else if (VAPaymentDetail.redirect_url) {
            checkOutUrl = VAPaymentDetail.redirect_url;
          }
        } else if (VAPaymentDetail.provider === "SEVIMA") {
          virtualAccount = VAPaymentDetail.virtual_account;
        } else if (
          VAPaymentDetail.provider === "BNIH2H" ||
          VAPaymentDetail.provider === "BSIH2H"
        ) {
          virtualAccount = VAPaymentDetail.virtualAccount;
        } else if (
          VAPaymentDetail.provider === "BTNH2H" ||
          VAPaymentDetail.provider === "BTN"
        ) {
          virtualAccount = VAPaymentDetail.va;
        } else if (VAPaymentDetail.provider === "SMARTLINK") {
          virtualAccount = VAPaymentDetail.data.payment_details.payment_code;
        } else if (VAPaymentDetail.provider === "PAYLABS") {
          if (VAPaymentDetail.paymentType === "BSIVA") {
            virtualAccount = VAPaymentDetail.vaCode.slice(4);
          } else {
            virtualAccount = VAPaymentDetail.vaCode;
          }
        } else if (VAPaymentDetail.provider === "DUITKU") {
          virtualAccount = VAPaymentDetail.vaNumber;
        } else if (VAPaymentDetail.provider === "WINPAY") {
          virtualAccount =
            VAPaymentDetail.virtualAccountData.virtualAccountNo.trim();
        } else if (VAPaymentDetail.provider === "BTNSYARIAHH2H") {
          virtualAccount = VAPaymentDetail.va;
        }

        this.setState({
          showPreviousTransactionModal: true,
          previousTransaction: {
            ...result.data.checkPreviousTransaction,
            VAPaymentDetail: JSON.parse(
              result.data.checkPreviousTransaction.VAPaymentDetail
            ),
            checkOutUrl,
            virtualAccount,
          },
          paymentChannelModalVisible: false,
        });
      } else {
        //Buka payment channellnya...

        const { paymentMethod, isPaymentH2H } = this.state;
        let fee = 0;
        if (isPaymentH2H) {
          if (selectedBills[0].PREFIX === "INKA") {
            fee = 0;
          } else {
            fee = 0;
          }
        } else {
          if (paymentMethod === "bca") {
            fee = 4500;
          } else if (paymentMethod === "linkAja") {
            fee = 4000;
          } else if (paymentMethod === "ovo") {
            fee = 0.05;
          } else if (paymentMethod === "dana") {
            fee = 0.05;
          } else if (paymentMethod === "bsi") {
            fee = 4500;
          } else if (paymentMethod === "bni") {
            fee = 4000;
          } else if (paymentMethod === "mandiri") {
            fee = 4000;
          } else if (paymentMethod === "btn") {
            fee = 1500;
          } else if (
            paymentMethod === "indomaret" ||
            paymentMethod === "alfamart"
          ) {
            fee = 5000;
          } else if (paymentMethod === "bsi-flip") {
            fee = 4000;
          } else if (paymentMethod === "danamon") {
            fee = 4000;
          } else if (paymentMethod === "pos") {
            fee = 5000;
          } else if (paymentMethod === "pegadaian") {
            fee = 5000;
          } else {
            fee = 4000;
          }
        }

        // const total =
        //   selectedBills
        //     .map((bill) => bill.unpaid)
        //     .reduce((acc, curr) => acc + curr, 0) + fee;

        let total = 0;

        if (paymentMethod === "ovo") {
          total = selectedBills
            .map((bill) => bill.unpaid)
            .reduce((acc, curr) => acc + curr, 0);

          fee = 0.05 * total;
          total = total + fee;
        } else if (paymentMethod === "dana") {
          total = selectedBills
            .map((bill) => bill.unpaid)
            .reduce((acc, curr) => acc + curr, 0);

          fee = 0.02 * total;
          total = total + fee;
        } else {
          total =
            selectedBills
              .map((bill) => bill.unpaid)
              .reduce((acc, curr) => acc + curr, 0) + fee;
        }

        const totalBill = selectedBills
          .map((bill) => bill.unpaid)
          .reduce((acc, curr) => acc + curr, 0);

        // console.log({ total, totalBill, fee });
        this.setState({
          checkOutModal: {
            visible: true,
            total,
            totalBill,
            fee,
          },
          selectedBills,
          paymentChannelModalVisible: false,
        });
      }

      // console.log(this.state.height);
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  closeCheckOutModal = () => {
    this.setState({
      selectedBills: [],
      checkOutModal: {
        visible: false,
        total: 0,
        fee: 0,
        totalBill: 0,
      },
    });
  };

  linkAjaContinue = (previousTransaction) => (e) => {
    window.open(previousTransaction.checkOutUrl, "__blank");
  };

  openPaymentChannel = () => {
    this.setState({
      paymentChannelModalVisible: true,
      paymentMethod: "",
      // paymentChannelModalVisible: true,

      // checkOutModal: {
      //   ...this.state.checkOutModal,
      //   visible: false,
      // },
    });
  };

  handleSelectPayment = (key) => (e) => {
    const { paymentChannels } = this.state;
    let isH2H = false;
    const found = paymentChannels.find((p) => p.name === key);
    if (found.isH2H && found.isH2H === true) {
      isH2H = true;
    }

    this.setState({
      paymentMethod: key,
      isPaymentH2H: isH2H,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  expandTagihan = (bulanTagihan) => (e) => {
    const { selectedTagihanBulan } = this.state;
    const findIndex = selectedTagihanBulan.indexOf(bulanTagihan);

    if (findIndex < 0) {
      this.setState({
        selectedTagihanBulan: [...selectedTagihanBulan, bulanTagihan],
      });
    } else {
      this.setState({
        selectedTagihanBulan: selectedTagihanBulan.filter(
          (t) => t !== bulanTagihan
        ),
      });
    }
  };

  render() {
    const {
      loading,
      selectedSiswa,
      listTagihan,
      showFilter,
      listSiswa,
      selectedBills,
      filterCriteria,
      listTahunAjaran,
      previousTransaction,
      showPreviousTransactionModal,
      checkOutModal,
      paymentChannelModalVisible,
      paymentMethod,
      successCheckOutModal,
      qrisObject,
      selectedTagihanBulan,
      paymentChannels,
    } = this.state;

    urlValues = queryString.parse(this.props.location.search);

    const VA_METHOD = paymentChannels.filter((p) => p.type === "va");
    const EWALLET_METHOD = paymentChannels.filter((p) => p.type === "ewallet");

    const ECOMMERCE_METHOD = paymentChannels.filter(
      (p) => p.type === "ecommerce"
    );

    // console.log("paymentChannels", paymentChannels);

    // const VA_METHOD = this.PAYMENT_METHODS.filter((p) => p.type === "va");
    // const EWALLET_METHOD = this.PAYMENT_METHODS.filter(
    //   (p) => p.type === "ewallet"
    // );
    // const ECOMMERCE_METHOD = this.PAYMENT_METHODS.filter(
    //   (p) => p.type === "ecommerce"
    // );

    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={<span>Filter</span>}
          visible={showFilter}
          onClose={(e) => {
            if (e) e.preventDefault();
            this.setState({
              showFilter: false,
            });
          }}
          onSubmit={this.submitFilter}
        >
          <div className="form-group">
            <label>Siswa</label>
            <select
              className="form-control"
              value={filterCriteria.studentId}
              onChange={this.handleFilterCriteria("studentId")}
              required
            >
              <option value="" disabled>
                Pilih Siswa
              </option>
              {listSiswa.map((siswa) => (
                <option value={siswa._id}>{siswa.name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Jenis Tagihan</label>
            <select
              className="form-control"
              value={filterCriteria.type}
              onChange={this.handleFilterCriteria("type")}
            >
              <option value="">Semua Jenis Tagihan</option>
              <option value="Bulanan">Bulanan</option>
              <option value="Insidental">Insidental</option>
            </select>
          </div>
          <div className="form-group">
            <label>Tahun Ajaran</label>
            <select
              className="form-control"
              value={filterCriteria.academicYear}
              onChange={this.handleFilterCriteria("academicYear")}
              required
            >
              <option value="" disabled>
                Pilih Tahun Ajaran
              </option>
              {listTahunAjaran.map((ta) => (
                <option value={ta.label}>
                  {ta.label} {ta.inProgress ? "(On Going)" : ""}
                </option>
              ))}
            </select>
          </div>
        </FormModal>

        <FormModal
          title={<span>Ooppss!!. Ada Tagihan Belum Dibayar</span>}
          visible={showPreviousTransactionModal}
          onClose={(e) => {
            if (e) e.preventDefault();
            this.setState({
              previousTransaction: null,
              showPreviousTransactionModal: false,
              selectedBills: [],
            });
          }}
        >
          {previousTransaction ? (
            <div>
              <p className="mb-4 text-justify">
                Anda memiliki tagihan yang belum dibayar. Silahkan melanjutkan
                pembayaran atau batalkan tagihan ini
              </p>

              <div className="d-flex justify-content-between mb-2">
                <p className="mb-0">No. Trans</p>
                <p className="mb-0">{previousTransaction.transactionNumber}</p>
              </div>

              <div className="d-flex justify-content-between mb-2">
                <p className="mb-0">Tgl. Trans</p>
                <p className="mb-0">
                  {dayjs(previousTransaction._createdAt).format(
                    "DD/MM/YY HH:mm:ss"
                  )}
                </p>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <p className="mb-0">Exp. Date</p>
                <p
                  className="mb-0"
                  style={{
                    color: "rgb(250, 46, 32)",
                  }}
                >
                  {dayjs(previousTransaction.expiredAt).format(
                    "DD/MM/YY HH:mm:ss"
                  )}
                </p>
              </div>

              <div className="d-flex justify-content-between mb-2">
                <p className="mb-0">Mtd. Bayar</p>
                <p className="mb-0">
                  {previousTransaction.paymentMethod.toUpperCase()}
                </p>
              </div>

              {previousTransaction.paymentMethod === "qris" ? null : (
                <div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="mb-0">No. VA</p>
                    <p
                      className="mb-0"
                      style={{
                        color: "rgb(45, 14, 237)",
                      }}
                    >
                      <b>{previousTransaction.virtualAccount}</b>
                    </p>
                  </div>
                  <div className="d-flex justify-content-end mb-2">
                    <CopyToClipboard
                      text={previousTransaction.virtualAccount}
                      onCopy={() => {
                        this.setState({ vaCopied: true });
                        addNotification({
                          level: "success",
                          message: "Copied",
                        });
                      }}
                    >
                      <button className="btn btn-sm btn-primary">
                        <i className="fa fa-copy" /> Salin
                      </button>
                    </CopyToClipboard>
                  </div>
                  <center>
                    <b>Detil Transaksi</b>
                  </center>
                  <div className="d-flex justify-content-between mb-2 mt-2">
                    <p
                      className="mb-0"
                      // style={{
                      //   fontSize: 12,
                      // }}
                    >
                      Tagihan
                    </p>
                    <p
                      className="mb-0"
                      // style={{
                      //   fontSize: 12,
                      // }}
                    >
                      Nominal Tagihan (Rp)
                    </p>
                  </div>
                  <hr
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      borderColor: "#000",
                    }}
                  />
                  {previousTransaction.Histories.map((det) => (
                    <div>
                      <div className="d-flex justify-content-between">
                        <p
                          className="mb-0"
                          // style={{
                          //   fontSize: 14,
                          // }}
                        >
                          {det.StudentBill.StudentBillType.name}
                        </p>
                        <p
                          className="mb-0"
                          // style={{
                          //   fontSize: 14,
                          // }}
                        >
                          {Number(det.paid)
                            .toLocaleString("id-ID")
                            .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                        </p>
                      </div>
                    </div>
                  ))}
                  <hr
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      borderColor: "#000",
                    }}
                  />

                  <div className="d-flex justify-content-between mb-1 mt-4">
                    <p className="mb-0">Total Tagihan</p>
                    <p className="mb-0">
                      <b>
                        Rp.{" "}
                        {Number(previousTransaction.total)
                          .toLocaleString("id-ID")
                          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                      </b>
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mb-1">
                    <p className="mb-0">Administrasi</p>
                    <p className="mb-0">
                      <b>
                        Rp.{" "}
                        {Number(previousTransaction.fee)
                          .toLocaleString("id-ID")
                          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                      </b>
                    </p>
                  </div>

                  <div className="d-flex justify-content-between mb-1">
                    <p className="mb-0">Sub. Total</p>
                    <p className="mb-0">
                      <b>
                        Rp.{" "}
                        {Number(
                          previousTransaction.total + previousTransaction.fee
                        )
                          .toLocaleString("id-ID")
                          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                      </b>
                    </p>
                  </div>

                  <div className="mt-4">
                    <div
                      className="d-flex justify-content-center"
                      onClick={this.handleCancel(previousTransaction)}
                    >
                      <div className="btn btn-sm btn-danger">
                        <i className="fa fa-times-circle" /> Batalkan Transaksi
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-money-bill-wave" /> Detil Pembayaran
            </span>
          }
          visible={checkOutModal.visible}
          onClose={(e) => {
            this.closeCheckOutModal();
          }}
        >
          {paymentMethod === "tokopedia" || paymentMethod === "shopee" ? (
            <div className="mb-2">
              <p style={{ textAlign: "justify" }}>
                Biaya transaksi dengan {paymentMethod.toUpperCase()} akan
                dikenakan biaya administrasi Rp. {checkOutModal.fee}
                /transaksi.
              </p>

              <p style={{ textAlign: "justify" }}>
                <b>
                  Biaya admin tersebut belum termasuk biaya admin dari{" "}
                  {paymentMethod.toUpperCase()}
                </b>
              </p>
            </div>
          ) : null}

          <div className="d-flex justify-content-between mb-2">
            <p
              className="mb-0"
              // style={{
              //   fontSize: 12,
              // }}
            >
              Tagihan
            </p>
            <p
              className="mb-0"
              // style={{
              //   fontSize: 12,
              // }}
            >
              Nominal Tagihan (Rp)
            </p>
          </div>
          <hr
            style={{
              marginTop: 0,
              marginBottom: 0,
              borderColor: "#000",
            }}
          />
          {selectedBills.map((det) => (
            <div>
              <div className="d-flex justify-content-between">
                <p className="mb-0">{det.StudentBillType.name}</p>
                <p className="mb-0">
                  {Number(det.unpaid)
                    .toLocaleString("id-ID")
                    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                </p>
              </div>
            </div>
          ))}

          <hr
            style={{
              marginTop: 0,
              marginBottom: 0,
              borderColor: "#000",
            }}
          />
          <div className="d-flex justify-content-between mb-1 mt-4">
            <p className="mb-0">Total Tagihan</p>
            <p className="mb-0">
              <b>
                Rp.{" "}
                {Number(checkOutModal.totalBill)
                  .toLocaleString("id-ID")
                  .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
              </b>
            </p>
          </div>
          <div className="d-flex justify-content-between mb-1">
            <p className="mb-0">Administrasi</p>
            <p className="mb-0">
              <b>
                Rp.{" "}
                {Number(checkOutModal.fee)
                  .toLocaleString("id-ID")
                  .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
              </b>
            </p>
          </div>

          <div className="d-flex justify-content-between mb-1">
            <p className="mb-0">Sub. Total</p>
            <p className="mb-0">
              <b>
                Rp.{" "}
                {Number(checkOutModal.total)
                  .toLocaleString("id-ID")
                  .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
              </b>
            </p>
          </div>

          <div className="mt-4">
            <div
              className="d-flex justify-content-center"
              // onClick={this.openPaymentChannel}
              onClick={this.submitPayment}
            >
              <div className="btn btn-sm btn-success">
                <i className="fa fa-check-circle" /> Lanjutkan Pembayaran
              </div>
            </div>
          </div>
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-money-bill-wave" /> Payment Channel
            </span>
          }
          visible={paymentChannelModalVisible}
          onClose={(e) => {
            this.setState({
              paymentChannelModalVisible: false,
              checkOutModal: {
                visible: false,
                total: 0,
                fee: 0,
                totalBill: 0,
              },
              selectedBills: [],
              paymentMethod: "",
            });
          }}
          onSubmit={this.openCheckOut(selectedBills)}
          // onSubmit={this.submitPayment}
        >
          <center>
            <Image
              style={{ width: 200, height: 200 }}
              source={require("../../assets/smartphone-payment.jpg")}
            />
          </center>

          <div>
            {selectedSiswa && selectedSiswa.PREFIX !== "RAHMATANSCHOOL" ? (
              <div>
                <div
                  className="mb-4"
                  style={{
                    // display: urlValues.PREFIX === "DEMO" ? "block" : "none",
                    display: EWALLET_METHOD.length > 0 ? "block" : "none",
                  }}
                >
                  <h5>E-Wallet</h5>
                  <div className="d-flex flex-column">
                    {EWALLET_METHOD.map((payment) => (
                      <section
                        className="card"
                        onClick={this.handleSelectPayment(payment.name)}
                        style={{
                          borderWidth: "4px",
                          borderRadius: "10px",
                          borderColor:
                            paymentMethod === payment.name
                              ? "#00a8ff"
                              : "#bdc3c7",
                          marginBottom: "5px",
                          // border: "block",
                          padding: 10,
                          marginBottom: 10,
                          // display:
                          //   payment.name === "ovo" || payment.name === "dana"
                          //     ? "none"
                          //     : "block",
                        }}
                      >
                        <div className="d-flex flex-row">
                          <View style={{ height: 50 }}>
                            <Image
                              style={{ width: 50, height: 50 }}
                              resizeMode="contain"
                              // source={require("../../assets/bca-logo.png")}
                              source={
                                payment.name === "linkAja"
                                  ? require("../../assets/linkAja.png")
                                  : ""
                              }
                            />
                          </View>
                          <div
                            className="d-flex align-items-center"
                            style={{
                              marginLeft: 10,
                            }}
                          >
                            <h5
                              style={{
                                margin: 0,
                              }}
                            >
                              {payment.label}
                            </h5>
                          </div>
                        </div>
                      </section>
                    ))}
                  </div>
                </div>
                {/* {router.query.PREFIX === "DEMO" ? (
                    <div className="mb-4">
                      <h5>QRIS</h5>
                      <div className="d-flex flex-column">
                        {QRIS_METHOD.map((payment) => (
                          <section
                            className="card"
                            onClick={this.handleSelectPayment(payment.name)}
                            style={{
                              borderWidth: "4px",
                              borderRadius: "10px",
                              borderColor:
                                paymentMethod === payment.name
                                  ? "#00a8ff"
                                  : "#bdc3c7",
                              marginBottom: "5px",
                              padding: 10,
                              marginBottom: 10,
                            }}
                          >
                            <div className="d-flex flex-row">
                              <img
                                src={payment.icon}
                                width="50"
                                height={37.5}
                              />
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  marginLeft: 10,
                                }}
                              >
                                <h5
                                  style={{
                                    margin: 0,
                                  }}
                                >
                                  {payment.label}
                                </h5>
                              </div>
                            </div>
                          </section>
                        ))}
                      </div>
                    </div>
                  ) : null} */}
              </div>
            ) : null}
          </div>
          {selectedSiswa && selectedSiswa.PREFIX === "DEMO" ? (
            <div
              className="mb-4"
              style={{
                display: "none",
                // selectedSiswa && selectedSiswa.PREFIX === "DEMO"
                //   ? "block"
                //   : "none",
              }}
            >
              <h5>E-Commerce</h5>
              <div className="d-flex flex-column">
                {ECOMMERCE_METHOD.map((payment) => (
                  <section
                    className="card"
                    onClick={this.handleSelectPayment(payment.name)}
                    // onClick={
                    //   checkHideSchoolPay === true
                    //     ? null
                    //     : this.handleSelectPayment(payment.name)
                    // }
                    style={{
                      borderWidth: "4px",
                      borderRadius: "10px",
                      borderColor:
                        this.state.paymentMethod === payment.name
                          ? "#00a8ff"
                          : "#bdc3c7",
                      marginBottom: "5px",
                      // border: "block",
                      padding: 10,
                      marginBottom: 10,
                    }}
                  >
                    <div className="d-flex flex-row">
                      <img
                        src={payment.icon}
                        style={{
                          width: "2.5rem",
                          height: "2.5rem",
                        }}
                      />
                      <div
                        className="d-flex align-items-center"
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        <h5
                          style={{
                            margin: 0,
                          }}
                        >
                          {payment.label}
                        </h5>
                      </div>
                    </div>
                  </section>
                ))}
              </div>
            </div>
          ) : null}

          <div className="d-flex flex-column">
            <h5>Virtual Account</h5>
            {VA_METHOD.filter((p) => p.type === "va").map((payment) => {
              if (selectedSiswa && selectedSiswa.PREFIX === "RAHMATANSCHOOL") {
                if (payment.name === "bsi") {
                  return (
                    <section
                      className="card"
                      onClick={this.handleSelectPayment(payment.name)}
                      style={{
                        borderWidth: "4px",
                        borderColor:
                          paymentMethod === payment.name
                            ? "#00a8ff"
                            : "#bdc3c7",
                        marginBottom: "5px",
                        borderRadius: "10px",
                        padding: 10,
                        marginBottom: 10,
                        // display:
                        //   payment.name === "ovo" || payment.name === "dana"
                        //     ? "none"
                        //     : "block",
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <View style={{ height: 50 }}>
                          <Image
                            style={{ width: 50, height: 50 }}
                            resizeMode="contain"
                            // source={require("../../assets/BSI_logo.png")}
                          />
                        </View>
                        <View
                          style={{ width: 250, height: 50, marginLeft: 10 }}
                        >
                          <Text style={{ marginTop: 13, fontSize: 20 }}>
                            {payment.label}
                          </Text>
                        </View>
                      </View>
                    </section>
                  );
                } else {
                  return <div />;
                }
              }
              return (
                <section
                  className="card"
                  onClick={this.handleSelectPayment(payment.name)}
                  style={{
                    borderWidth: "4px",
                    borderColor:
                      paymentMethod === payment.name ? "#00a8ff" : "#bdc3c7",
                    marginBottom: "5px",
                    borderRadius: "10px",
                    padding: 10,
                    marginBottom: 10,
                    display: payment.display,
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={{ height: 50 }}>
                      <Image
                        style={{ width: 50, height: 50 }}
                        resizeMode="contain"
                        // source={require("../../assets/bca-logo.png")}
                        source={
                          payment.name === "bca"
                            ? require("../../assets/bca-logo.png")
                            : payment.name === "bri"
                            ? require("../../assets/Logo-Bank-BRI.png")
                            : payment.name === "bni"
                            ? require("../../assets/Logo-BNI-46.png")
                            : payment.name === "mandiri"
                            ? require("../../assets/Logo-Bank-Mandiri.png")
                            : payment.name === "cimb"
                            ? require("../../assets/cimb.png")
                            : payment.name === "permata"
                            ? require("../../assets/permata.png")
                            : payment.name === "bsi"
                            ? require("../../assets/BSI_logo.png")
                            : payment.name === "btn"
                            ? require("../../assets/Btn.png")
                            : payment.name === "indomaret"
                            ? require("../../assets/indomaretlogo.png")
                            : payment.name === "alfamart"
                            ? require("../../assets/alfamartlogo.png")
                            : payment.name === "bsi-flip"
                            ? require("../../assets/BSI_logo.png")
                            : payment.name === "danamon-flip"
                            ? require("../../assets/Danamon.png")
                            : payment.name === "pegadaian"
                            ? require("../../assets/pegadaian.png")
                            : payment.name === "pos"
                            ? require("../../assets/ptpos.png")
                            : ""
                        }
                      />
                    </View>
                    <View style={{ width: 250, height: 50, marginLeft: 10 }}>
                      <Text style={{ marginTop: 13, fontSize: 20 }}>
                        {payment.label}
                      </Text>
                    </View>
                  </View>
                </section>
              );
            })}
          </div>
        </FormModal>

        <FormModal
          visible={successCheckOutModal.visible}
          title={<span>{`Pembayaran via ${paymentMethod.toUpperCase()}`}</span>}
          onClose={(e) => {
            if (e) e.preventDefault();
            this.setState({
              successCheckOutModal: {
                visible: false,
                vaNumber: "",
                bankCode: "",
              },
            });
          }}
        >
          <div className="text-center">
            <h5 style={{ marginBottom: 10 }}>
              Harap Bayar Tagihan Anda Pada VA:
            </h5>
            <h3 style={{ color: "#0277bd" }}>
              {successCheckOutModal.vaNumber
                ? successCheckOutModal.vaNumber
                : "-"}
            </h3>

            <CopyToClipboard
              text={successCheckOutModal.vaNumber}
              onCopy={() => {
                this.setState({ vaCopied: true });
                addNotification({
                  level: "success",
                  message: "Copied",
                });
              }}
            >
              <button className="btn btn-sm btn-primary">
                <i className="fa fa-copy" /> Copy to Clipboard
              </button>
            </CopyToClipboard>

            {paymentMethod === "bca" ? (
              <div>
                <div>
                  <p>Kunjungi link dibawah ini untuk petunjuk pembayaran: </p>
                  <div className="form-group">
                    <label>&middot;BCA Mobile Banking/M-BCA</label> <br />
                    <a href="https://youtu.be/SlcGsxJpBRs">
                      https://youtu.be/SlcGsxJpBRs
                    </a>
                  </div>
                </div>
              </div>
            ) : paymentMethod === "mandiri" ? (
              <div>
                <p>Kunjungi link dibawah ini untuk petunjuk pembayaran: </p>
                <div className="form-group">
                  <label>&middot;Mandiri Mobile Banking</label> <br />
                  <a href="https://youtu.be/Vgx6H-1OCKs?si=nfOaLKzY3HBD7Gb4">
                    https://youtu.be/Vgx6H-1OCKs?si=nfOaLKzY3HBD7Gb4
                  </a>
                </div>
              </div>
            ) : paymentMethod === "bsi" ? (
              <div>
                <p>Kunjungi link dibawah ini untuk petunjuk pembayaran: </p>
                <div className="form-group">
                  <label>&middot;BSI Mobile Banking</label> <br />
                  <a href="https://youtu.be/3mSikSynrAc">
                    https://youtu.be/3mSikSynrAc
                  </a>
                </div>
              </div>
            ) : paymentMethod === "bri" ? (
              <div>
                <p>Kunjungi link dibawah ini untuk petunjuk pembayaran: </p>
                <div className="form-group">
                  <label>&middot;BRI Mobile Banking</label> <br />
                  <a href="ttps://youtu.be/lnwdxQum4p4">
                    ttps://youtu.be/lnwdxQum4p4
                  </a>
                </div>
              </div>
            ) : paymentMethod === "bni" ? (
              <div>
                <p>Kunjungi link dibawah ini untuk petunjuk pembayaran: </p>
                <div className="form-group">
                  <label>&middot;BNI Mobile Banking</label> <br />
                  <a href="https://youtu.be/rpIM1rxd-Zk">
                    https://youtu.be/rpIM1rxd-Zk
                  </a>
                </div>
              </div>
            ) : paymentMethod === "cimb" ? (
              <div>
                <p>Kunjungi link dibawah ini untuk petunjuk pembayaran: </p>
                <div className="form-group">
                  <label>&middot;CIMB Niaga Mobile Banking</label> <br />
                  <a href="https://youtu.be/pwytIZAue_U">
                    https://youtu.be/pwytIZAue_U
                  </a>
                </div>
              </div>
            ) : paymentMethod === "permata" ? (
              <div>
                <p>Kunjungi link dibawah ini untuk petunjuk pembayaran: </p>
                <div className="form-group">
                  <label>&middot;Permata Mobile Banking</label> <br />
                  <a href="https://youtu.be/U4kO6QcPsro">
                    https://youtu.be/U4kO6QcPsro
                  </a>
                </div>
              </div>
            ) : paymentMethod === "btn" ? (
              <div>
                <p>Kunjungi link dibawah ini untuk petunjuk pembayaran: </p>
                <div className="form-group">
                  <label>&middot;BTN Mobile</label> <br />
                  <a href="https://youtu.be/5QlEqqGvCjU">
                    https://youtu.be/5QlEqqGvCjU
                  </a>
                </div>
              </div>
            ) : paymentMethod === "indomaret" ? (
              <div>
                <p>Kunjungi link dibawah ini untuk petunjuk pembayaran: </p>
                <div className="form-group">
                  <label>&middot;Indomaret/Alfamaret</label> <br />
                  <a href="https://youtu.be/Emt36Msqm9s">
                    https://youtu.be/Emt36Msqm9s
                  </a>
                </div>
              </div>
            ) : paymentMethod === "alfamart" ||
              paymentMethod === "pos" ||
              paymentMethod === "pegadaian" ? (
              <div>
                <p>Kunjungi link dibawah ini untuk petunjuk pembayaran: </p>
                <div className="form-group">
                  <label>&middot;Alfamart/Pos Indonesia/Pegadaian</label> <br />
                  <a href="https://youtu.be/o7g-ayMZu4Y">
                    https://youtu.be/o7g-ayMZu4Y
                  </a>
                </div>
              </div>
            ) : null}
          </div>
        </FormModal>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />
        <LoadingOverlay loading={loading} />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-file-invoice"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {translate("Tagihan dan Pembayaran")}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <div
            style={{
              backgroundColor: "#fff",
            }}
            ref={(divElement) => {
              this.divElement = divElement;
            }}
          >
            <div>
              {loading ? (
                <ShimmerThumbnail className="m-0" rounded />
              ) : (
                <div className="py-2 px-2">
                  <div className="row justify-content-center">
                    <div className="col-3">
                      <Image
                        style={{ width: 80, height: 80 }}
                        // source={require("../assets/owl-laptop-study.png")}
                        source={require("../../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-9 p-0 d-flex align-items-center">
                      <div
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 16,
                            margin: 0,
                          }}
                        >
                          {selectedSiswa
                            ? selectedSiswa.name.length < 30
                              ? selectedSiswa.name
                              : selectedSiswa.name.slice(0, 27) + "..."
                            : ""}
                        </p>

                        <p
                          className="m-0"
                          style={{
                            fontSize: 14,
                            margin: 0,
                          }}
                        >
                          <b>
                            {selectedSiswa && selectedSiswa.SchoolInformation
                              ? selectedSiswa.SchoolInformation.name.length <=
                                30
                                ? selectedSiswa.SchoolInformation.name
                                : selectedSiswa.SchoolInformation.name.slice(
                                    0,
                                    27
                                  ) + "..."
                              : "-"}
                          </b>
                        </p>
                        <p
                          className="m-0"
                          style={{
                            fontSize: 14,
                            margin: 0,
                          }}
                        >
                          <b>
                            {selectedSiswa && selectedSiswa.Classroom
                              ? selectedSiswa.Classroom.name.length <= 30
                                ? selectedSiswa.Classroom.name
                                : selectedSiswa.Classroom.name.slice(0, 27) +
                                  "..."
                              : "-"}
                          </b>
                        </p>

                        <p
                          className="m-0"
                          style={{
                            fontSize: 14,
                            margin: 0,
                          }}
                        >
                          <b>
                            {selectedSiswa && selectedSiswa.status
                              ? selectedSiswa.status
                              : "-"}
                          </b>
                        </p>
                      </div>
                      {/* <i
                        className="fa fa-filter ml-4"
                        style={{
                          fontSize: 18,
                        }}
                        onClick={this.openFilter}
                      /> */}
                    </div>
                  </div>
                  <center>
                    <button
                      type="button"
                      onClick={this.openFilter}
                      style={{
                        zIndex: 9999,
                        backgroundColor: "#00a8ff",
                        borderWidth: 0,
                        borderRadius: 40,
                        padding: "5px 20px 5px 20px",
                        boxShadow: "0px -2px 5px 2px #ddd",
                      }}
                      className="btn btn-sm btn-primary"
                    >
                      <b>
                        <i className="fa fa-filter" /> Filter Siswa/Tagihan
                      </b>
                    </button>
                  </center>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className="px-2"
          style={{
            marginTop:
              this.state.height < 100
                ? this.state.height + 100
                : this.state.height + 100,
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <button className="btn btn-md btn-primary mr-2">
              Daftar Tagihan
            </button>
            <button className="btn btn-md btn-light" onClick={this.openHistory}>
              Riwayat Pembayaran
            </button>
          </View>
          <View
            style={{
              flex: 1,
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: 25,
                marginBottom: 10,
              }}
            >
              Daftar Tagihan
            </Text>
          </View>

          <View
            style={{
              marginBottom: "50px",
              backgroundColor: "#fff",
              borderRadius: "10px",
            }}
          >
            {loading || !listTagihan ? (
              <ShimmerThumbnail className="m-0" rounded />
            ) : (
              Object.keys(listTagihan).map((tagihan) => {
                const dataTagihan = lodash.get(listTagihan, tagihan, []);
                const clickedTagihanIndex = selectedTagihanBulan.findIndex(
                  (t) => t === tagihan
                );
                const tagihanBelumLunas = dataTagihan.filter(
                  (t) => t.status !== "LUNAS"
                );
                return (
                  <div className="mb-2">
                    <div className="px-2 py-2">
                      <div
                        className="d-flex justify-content-between align-items-center"
                        onClick={this.expandTagihan(tagihan)}
                      >
                        <div className="d-flex flex-column">
                          <p
                            className="m-0"
                            style={{
                              fontSize: 18,
                            }}
                          >
                            <b>
                              Tagihan {tagihan} -{" "}
                              {dataTagihan.length > 0
                                ? dataTagihan[0].academicYear
                                : ""}
                            </b>
                          </p>
                          <p
                            className="m-0"
                            style={{
                              fontSize: 12,
                              color:
                                tagihanBelumLunas.length > 0 ? "red" : "green",
                            }}
                          >
                            <b>
                              {tagihanBelumLunas.length > 0
                                ? `Terdapat ${tagihanBelumLunas.length} tagihan
                                belum lunas`
                                : "Tagihan Sudah Lunas"}
                            </b>
                          </p>
                        </div>
                        <p
                          className="m-0"
                          style={{
                            fontSize: 18,
                          }}
                        >
                          {clickedTagihanIndex > -1 ? (
                            <i className="fa fa-caret-up" />
                          ) : (
                            <i className="fa fa-caret-down" />
                          )}
                        </p>
                      </div>
                      {dataTagihan.map((t) => {
                        const index = selectedBills
                          .map((b) => b._id)
                          .findIndex((bi) => bi === t._id);

                        if (clickedTagihanIndex === -1) {
                          return <div />;
                        }

                        return (
                          <div
                            className="card"
                            style={{
                              marginBottom: 10,
                              borderRadius: 10,
                            }}
                            onClick={
                              t.status === "LUNAS"
                                ? null
                                : this.handleSelectBill(t)
                            }
                          >
                            <div
                              className="card-header d-flex flex-row justify-content-left align-items-center"
                              style={{
                                backgroundColor:
                                  t.status === "LUNAS"
                                    ? "#66BB6A"
                                    : t.status === "BELUM LUNAS" && index < 0
                                    ? "#F44336"
                                    : "#1976D2",
                                color: "#fff",
                                fontSize: 16,
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                              }}
                            >
                              <div style={{ marginLeft: 10 }}>
                                <b>{t.StudentBillType.name.toUpperCase()}</b>
                              </div>
                            </div>

                            <div
                              className="card-body"
                              style={{
                                fontSize: 16,
                              }}
                            >
                              <div className="d-flex justify-content-between align-items-center">
                                <div
                                  className="d-flex flex-column"
                                  style={{
                                    flex: 1,
                                  }}
                                >
                                  <div className="row">
                                    <div
                                      className="col-5 p-0"
                                      style={{
                                        fontSize: 12,
                                      }}
                                    >
                                      Nominal:
                                    </div>
                                    <div
                                      className="col-7 pl-2"
                                      style={{
                                        fontSize: 12,
                                      }}
                                    >
                                      <b>
                                        Rp.{" "}
                                        {Number(t.amount)
                                          .toLocaleString("id-ID")
                                          .replace(
                                            /(\d)(?=(\d{3})+\.)/g,
                                            "$1,"
                                          )}
                                      </b>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div
                                      className="col-5 p-0"
                                      style={{
                                        fontSize: 12,
                                      }}
                                    >
                                      Dibayar:
                                    </div>
                                    <div
                                      className="col-7 pl-2"
                                      style={{
                                        fontSize: 12,
                                      }}
                                    >
                                      <b>
                                        Rp.{" "}
                                        {Number(t.paid)
                                          .toLocaleString("id-ID")
                                          .replace(
                                            /(\d)(?=(\d{3})+\.)/g,
                                            "$1,"
                                          )}
                                      </b>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div
                                      className="col-5 p-0"
                                      style={{
                                        fontSize: 12,
                                      }}
                                    >
                                      Sisa:
                                    </div>
                                    <div
                                      className="col-7 pl-2"
                                      style={{
                                        fontSize: 12,
                                      }}
                                    >
                                      <b>
                                        Rp.{" "}
                                        {Number(t.unpaid)
                                          .toLocaleString("id-ID")
                                          .replace(
                                            /(\d)(?=(\d{3})+\.)/g,
                                            "$1,"
                                          )}
                                      </b>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <Image
                                    style={{ width: 80, height: 80 }}
                                    source={
                                      t.status === "LUNAS"
                                        ? require("../../assets/st-bill-img-pay-done.svg")
                                        : require("../../assets/st-bill-img-pay.svg")
                                    }
                                  />
                                  <center>
                                    <p
                                      className="mb-0"
                                      style={{
                                        fontSize: 12,
                                        color:
                                          t.status === "LUNAS"
                                            ? "#66BB6A"
                                            : "#F44336",
                                      }}
                                    >
                                      <b>{t.status}</b>
                                    </p>
                                  </center>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      {/* {dataTagihan.map((t) => {
                        const index = selectedBills
                          .map((b) => b._id)
                          .findIndex((bi) => bi === t._id);
                        return (
                          <div
                            className={"px-2 py-2 mb-2 shadow"}
                            style={{
                              backgroundColor: "#fff",
                              border:
                                t.status === "LUNAS"
                                  ? "3px solid rgb(114, 248, 8)"
                                  : t.status === "BELUM LUNAS" && index < 0
                                  ? "3px solid rgb(250, 46, 32)"
                                  : "3px solid rgb(45, 14, 237)",
                              borderRadius: "10px",
                            }}
                            onClick={
                              t.status === "LUNAS"
                                ? null
                                : this.handleSelectBill(t)
                            }
                          >
                            <View
                              style={{
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginBottom: 5,
                              }}
                            >
                              <View>
                                <Text
                                  style={{
                                    fontSize: 14,
                                  }}
                                >
                                  Tagihan
                                </Text>
                              </View>
                              <View>
                                <Text
                                  style={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {t.StudentBillType.name.toUpperCase()}
                                </Text>
                              </View>
                            </View>
                            <View
                              style={{
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginBottom: 5,
                              }}
                            >
                              <View>
                                <Text
                                  style={{
                                    fontSize: 14,
                                  }}
                                >
                                  Nominal
                                </Text>
                              </View>
                              <View>
                                <Text
                                  style={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Rp.{" "}
                                  {Number(t.amount)
                                    .toLocaleString("id-ID")
                                    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                                </Text>
                              </View>
                            </View>

                            <View
                              style={{
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginBottom: 5,
                              }}
                            >
                              <View>
                                <Text
                                  style={{
                                    fontSize: 14,
                                  }}
                                >
                                  Dibayar
                                </Text>
                              </View>
                              <View>
                                <Text
                                  style={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Rp.{" "}
                                  {Number(t.paid)
                                    .toLocaleString("id-ID")
                                    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                                </Text>
                              </View>
                            </View>

                            <View
                              style={{
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginBottom: 5,
                              }}
                            >
                              <View>
                                <Text
                                  style={{
                                    fontSize: 14,
                                  }}
                                >
                                  Sisa Bayar
                                </Text>
                              </View>
                              <View>
                                <Text
                                  style={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Rp.{" "}
                                  {Number(t.unpaid)
                                    .toLocaleString("id-ID")
                                    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                                </Text>
                              </View>
                            </View>
                          </div>
                        );
                      })} */}
                      <hr style={{ margin: 5, borderColor: "#000" }} />
                    </div>
                  </div>
                );
              })
            )}
          </View>
        </div>

        <div className="fixed-bottom">
          <button
            className="btn btn-sm btn-primary btn-block py-2"
            // onClick={this.openCheckOut(selectedBills)}
            onClick={this.openPaymentChannel}
          >
            <i className="fa fa-shopping-cart" /> Bayar {selectedBills.length}{" "}
            Tagihan
          </button>
          {/* <div className="d-flex justify-content-center">
            <button
              className="btn btn-sm btn-primary mx-2"
              style={{
                borderRadius: "50%",
                width: "50px",
                height: "50px",
              }}
              onClick={this.openFilter}
            >
              <i className="fa fa-filter" />
            </button>

            {selectedBills.length === 0 ? (
              <button
                className="btn btn-sm mx-2"
                style={{
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                  backgroundColor: "#B0BEC5",
                  color: "#fff",
                }}
              >
                <i className="fa fa-shopping-cart" />
              </button>
            ) : (
              <button
                className="btn btn-sm btn-success mx-2"
                style={{
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                  position: "relative",
                }}
                onClick={this.openCheckOut(selectedBills)}
              >
                <i className="fa fa-shopping-cart" />

                <span
                  style={{
                    position: "absolute",
                    width: "20px",
                    height: "20px",
                    right: 0,
                    top: -10,
                    color: "#fff",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    fontSize: 16,
                  }}
                >
                  {selectedBills.length}
                </span>
              </button>
            )}

            <button
              className="btn btn-sm btn-info mx-2"
              style={{
                borderRadius: "50%",
                width: "50px",
                height: "50px",
              }}
              onClick={this.openHistory}
            >
              <i className="fa fa-history" />
            </button>
          </div> */}
        </div>
      </View>
    );
  }

  handleCancel = (previousTransaction) => async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();
    try {
      if (
        window.confirm(
          "Apakah Anda yakin untuk membatalkan transaksi ini? Transaksi yang dibatalkan tidak dapat digunakan kembali"
        )
      ) {
        await mwvClient.mutate({
          mutation: CANCEL_WAITING_TRANSACTION,
          variables: {
            _id: previousTransaction._id,
          },
          fetchPolicy: "no-cache",
        });

        addNotification({
          level: "success",
          message: "Tagihan dibatalkan",
        });

        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);

        this.setState({
          previousTransaction: null,
          showPreviousTransactionModal: false,
          selectedBills: [],
          paymentMethod: "",
          paymentChannelModalVisible: false,
        });
      }
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  submitPayment = async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();
    try {
      urlValues = queryString.parse(this.props.location.search);
      const {
        paymentMethod,
        selectedBills,
        checkOutModal,
        successCheckOutModal,
        isPaymentH2H,
      } = this.state;
      if (!paymentMethod) {
        throw {
          message: `Anda belum memilih metode pembayaran tagihan!`,
        };
      }

      const nowHours = dayjs().hour();
      if (nowHours >= 21 || nowHours <= 4) {
        throw {
          message:
            "Cut Off. Silahkan lakukan pembayaran diatas jam 04:00 - 21:00",
        };
      }

      let histories = selectedBills.map((bill) => {
        return {
          studentBillId: bill._id,
          paid: bill.unpaid,
        };
      });

      // console.log({ isPaymentH2H });

      if (isPaymentH2H) {
        const result = await mwvClient.mutate({
          mutation: CREATE_SCHOOL_PAY_BILL_H2H,
          variables: {
            sessionId: urlValues.sessionId,
            studentBillIds: selectedBills.map((b) => b._id),
            paymentMethod,
            total: checkOutModal.totalBill,
            fee: checkOutModal.fee,
            phoneNumber: "", //this.state.phoneNumber,
            histories,
          },
          fetchPolicy: "no-cache",
        });

        addNotification({
          level: "success",
          message: "Checkout tagihan berhasil",
        });

        const vaDetailResult = JSON.parse(
          result.data.createSchoolPayBillWithH2H.VAPaymentDetail
        );
        if (vaDetailResult.provider === "BNIH2H") {
          this.setState({
            successCheckOutModal: {
              visible: true,
              vaNumber: vaDetailResult.virtualAccount,
              bankCode: "BNI",
            },
            paymentChannelModalVisible: false,
            checkOutModal: {
              visible: false,
              total: 0,
              fee: 0,
              totalBill: 0,
            },
            selectedBills: [],
          });
        } else if (vaDetailResult.provider === "BSIH2H") {
          this.setState({
            successCheckOutModal: {
              visible: true,
              vaNumber: vaDetailResult.virtualAccount,
              bankCode: "BSI",
            },
            paymentChannelModalVisible: false,
            checkOutModal: {
              visible: false,
              total: 0,
              fee: 0,
              totalBill: 0,
            },
            selectedBills: [],
          });
        } else if (vaDetailResult.provider === "BTNSYARIAHH2H") {
          this.setState({
            successCheckOutModal: {
              visible: true,
              vaNumber: vaDetailResult.va,
              bankCode: "BTNSYARIAHH2H",
            },
            paymentChannelModalVisible: false,
            checkOutModal: {
              visible: false,
              total: 0,
              fee: 0,
              totalBill: 0,
            },
            selectedBills: [],
          });
        } else if (vaDetailResult.provider === "BTNH2H") {
          this.setState({
            successCheckOutModal: {
              visible: true,
              vaNumber: vaDetailResult.va,
              bankCode: "BTN",
            },
            paymentChannelModalVisible: false,
            checkOutModal: {
              visible: false,
              total: 0,
              fee: 0,
              totalBill: 0,
            },
            selectedBills: [],
          });
        } else if (vaDetailResult.provider === "MCP") {
          let vaNumber = "";

          if (vaDetailResult.mcpVersion && vaDetailResult.mcpVersion === "v2") {
            vaNumber = vaDetailResult.payment_details.va_number;
          } else {
            vaNumber = vaDetailResult.payment_code;
          }
          this.setState({
            successCheckOutModal: {
              visible: true,
              vaNumber,
              bankCode: vaDetailResult.bankCode,
            },
            paymentChannelModalVisible: false,
            checkOutModal: {
              visible: false,
              total: 0,
              fee: 0,
              totalBill: 0,
            },
            selectedBills: [],
          });
        }
      } else {
        const result = await mwvClient.mutate({
          mutation: CREATE_SCHOOL_PAY_BILL,
          variables: {
            sessionId: urlValues.sessionId,
            studentBillIds: selectedBills.map((b) => b._id),
            paymentMethod,
            total: checkOutModal.totalBill,
            fee: checkOutModal.fee,
            phoneNumber: "", //this.state.phoneNumber,
            histories,
          },
          fetchPolicy: "no-cache",
        });

        addNotification({
          level: "success",
          message: "Checkout tagihan berhasil",
        });

        if (
          paymentMethod === "mandiri" ||
          paymentMethod === "bri" ||
          paymentMethod === "bni" ||
          paymentMethod === "bca" ||
          paymentMethod === "bsi" ||
          paymentMethod === "permata" ||
          paymentMethod === "cimb" ||
          paymentMethod === "muamalat" ||
          paymentMethod === "btn" ||
          paymentMethod === "indomaret" ||
          paymentMethod === "alfamart" ||
          paymentMethod === "danamon" ||
          paymentMethod === "pos" ||
          paymentMethod === "pegadaian"
        ) {
          if (result.data.createSchoolPayBill.VAPaymentDetail) {
            const vaDetailResult = JSON.parse(
              result.data.createSchoolPayBill.VAPaymentDetail
            );
            if (vaDetailResult.provider === "MCP") {
              let vaNumber = "";

              if (
                vaDetailResult.mcpVersion &&
                vaDetailResult.mcpVersion === "v2"
              ) {
                vaNumber = vaDetailResult.payment_details.va_number;
              } else {
                vaNumber = vaDetailResult.payment_code;
              }
              this.setState({
                successCheckOutModal: {
                  visible: true,
                  vaNumber,
                  bankCode: vaDetailResult.bankCode,
                },
                paymentChannelModalVisible: false,
                checkOutModal: {
                  visible: false,
                  total: 0,
                  fee: 0,
                  totalBill: 0,
                },
                selectedBills: [],
              });
            } else if (vaDetailResult.provider === "XENDIT") {
              let vaNumber = "";
              if (vaDetailResult.bank_code === "BSI") {
                vaNumber = vaDetailResult.account_number.slice(
                  4,
                  vaDetailResult.account_number.length
                );
              } else {
                vaNumber = vaDetailResult.account_number;
              }

              this.setState({
                successCheckOutModal: {
                  visible: true,
                  vaNumber,
                  bankCode: vaDetailResult.bank_code,
                },
                paymentChannelModalVisible: false,
                checkOutModal: {
                  visible: false,
                  total: 0,
                  fee: 0,
                  totalBill: 0,
                },
                selectedBills: [],
              });
            } else if (vaDetailResult.provider === "FASPAY") {
              this.setState({
                successCheckOutModal: {
                  visible: true,
                  vaNumber: vaDetailResult.va_number,
                  bankCode: vaDetailResult.bankCode,
                },
                paymentChannelModalVisible: false,
                checkOutModal: {
                  visible: false,
                  total: 0,
                  fee: 0,
                  totalBill: 0,
                },
                selectedBills: [],
              });
            } else if (vaDetailResult.provider === "SEVIMA") {
              this.setState({
                successCheckOutModal: {
                  visible: true,
                  vaNumber: vaDetailResult.virtual_account,
                  bankCode: vaDetailResult.bankCode,
                },
                paymentChannelModalVisible: false,
                checkOutModal: {
                  visible: false,
                  total: 0,
                  fee: 0,
                  totalBill: 0,
                },
                selectedBills: [],
              });
            } else if (vaDetailResult.provider === "BTN") {
              this.setState({
                successCheckOutModal: {
                  visible: true,
                  vaNumber: vaDetailResult.va,
                  bankCode: "BTN",
                },
                paymentChannelModalVisible: false,
                checkOutModal: {
                  visible: false,
                  total: 0,
                  fee: 0,
                  totalBill: 0,
                },
                selectedBills: [],
              });
            } else if (vaDetailResult.provider === "SMARTLINK") {
              this.setState({
                successCheckOutModal: {
                  visible: true,
                  vaNumber: vaDetailResult.data.payment_details.payment_code,
                  bankCode: vaDetailResult.bankCode,
                },
                paymentChannelModalVisible: false,
                checkOutModal: {
                  visible: false,
                  total: 0,
                  fee: 0,
                  totalBill: 0,
                },
                selectedBills: [],
              });
            } else if (vaDetailResult.provider === "PAYLABS") {
              if (vaDetailResult.paymentType === "BSIVA") {
                this.setState({
                  successCheckOutModal: {
                    visible: true,
                    vaNumber: vaDetailResult.vaCode.slice(4),
                    bankCode: vaDetailResult.bankCode,
                  },
                  paymentChannelModalVisible: false,
                  checkOutModal: {
                    visible: false,
                    total: 0,
                    fee: 0,
                    totalBill: 0,
                  },
                  selectedBills: [],
                });
              } else {
                this.setState({
                  successCheckOutModal: {
                    visible: true,
                    vaNumber: vaDetailResult.vaCode,
                    bankCode: vaDetailResult.bankCode,
                  },
                  paymentChannelModalVisible: false,
                  checkOutModal: {
                    visible: false,
                    total: 0,
                    fee: 0,
                    totalBill: 0,
                  },
                  selectedBills: [],
                });
              }
            } else if (
              vaDetailResult.provider === "DUITKU" ||
              vaDetailResult.provider === "WINPAY"
            ) {
              this.setState({
                successCheckOutModal: {
                  visible: true,
                  vaNumber: vaDetailResult.vaNumber,
                  bankCode: vaDetailResult.bankCode,
                },
                paymentChannelModalVisible: false,
                checkOutModal: {
                  visible: false,
                  total: 0,
                  fee: 0,
                  totalBill: 0,
                },
                selectedBills: [],
              });
            }
          }
        } else if (
          paymentMethod === "linkAja" ||
          paymentMethod === "ovo" ||
          paymentMethod === "dana"
        ) {
          const bill = result.data.createSchoolPayBill;
          window.location.href = bill.checkoutUrl;
          return;
          // if (
          //   bill.paymentMethod === "dana" ||
          //   bill.paymentMethod === "linkAja"
          // ) {
          //   window.location.href = bill.checkoutUrl;
          //   return;
          // }
        } else if (paymentMethod === "bsi-flip") {
          const bill = result.data.createSchoolPayBill;
          if (bill.checkoutUrl.includes("https")) {
            // window.location.href = `${bill.checkoutUrl}`;
            window.open(bill.checkoutUrl, "__blank");
          } else {
            window.location.href = `https://${bill.checkoutUrl}`;
          }
          window.location.href = bill.checkoutUrl;
          return;
        }
      }
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
  // uri: "http://qssd.server.inimedia.co.id:8001/graphql",
});

const ALL_STUDENT_QUERIES = gql`
  query listQuery($_id: String, $PREFIX: String, $studentId: String) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      Classroom {
        _id
        academicYear
        name
      }
      status
      PREFIX
      photoUrl
    }
    allAcademicYears(PREFIX: $PREFIX) {
      _id
      label
      inProgress
    }
    h2hChecker(PREFIX: $PREFIX, studentId: $studentId) {
      name
      label
      icon
      type
      isH2H
    }
  }
`;

const ALL_ACADEMIC_YEAR_BY_STUDENT = gql`
  query allAcademicYearsByStudent($studentId: String) {
    allAcademicYearsByStudent(studentId: $studentId) {
      _id
      label
      inProgress
    }
  }
`;

const ALL_STUDENT_BILLS = gql`
  query allStudentBillNew(
    $studentId: String!
    $academicYear: String!
    $type: String!
    $sessionId: String
  ) {
    allStudentBillNew(
      studentId: $studentId
      academicYear: $academicYear
      type: $type
      sessionId: $sessionId
    ) {
      _id
      academicYear
      studentId
      StudentBillType {
        _id
        name
        academicYear
      }
      month
      amount
      paid
      unpaid
      tags
      status
      PREFIX
    }
  }
`;

const CHECK_PREVIOUS_TRANSACTION = gql`
  mutation checkPreviousTransaction($sessionId: String!, $studentId: String!) {
    checkPreviousTransaction(sessionId: $sessionId, studentId: $studentId) {
      _id
      total
      fee
      paymentMethod
      transactionNumber
      status
      VAPaymentDetail
      Histories {
        _id
        StudentBill {
          _id
          StudentBillType {
            _id
            name
          }
        }
        paid
      }
      _createdAt
      expiredAt
    }
  }
`;

const CANCEL_WAITING_TRANSACTION = gql`
  mutation cancelTransaction($_id: String!) {
    cancelTransaction(_id: $_id)
  }
`;

const CREATE_SCHOOL_PAY_BILL = gql`
  mutation createSchoolPayBill(
    $sessionId: String!
    $studentBillIds: [String]
    $paymentMethod: String!
    $total: Int!
    $fee: Int!
    $histories: [CreatePaidHistory]
    $phoneNumber: String
  ) {
    createSchoolPayBill(
      input: {
        sessionId: $sessionId
        studentBillIds: $studentBillIds
        paymentMethod: $paymentMethod
        total: $total
        fee: $fee
        histories: $histories
        phoneNumber: $phoneNumber
      }
    ) {
      _id
      expiredAt
      paymentMethod
      total
      transactionNumber
      status
      checkoutUrl

      VAPaymentDetail
      paymentUrl
    }
  }
`;

const CREATE_SCHOOL_PAY_BILL_H2H = gql`
  mutation createSchoolPayBillWithH2H(
    $sessionId: String!
    $studentBillIds: [String]
    $paymentMethod: String!
    $total: Int!
    $fee: Int!
    $histories: [CreatePaidHistory]
    $phoneNumber: String
  ) {
    createSchoolPayBillWithH2H(
      input: {
        sessionId: $sessionId
        studentBillIds: $studentBillIds
        paymentMethod: $paymentMethod
        total: $total
        fee: $fee
        histories: $histories
        phoneNumber: $phoneNumber
      }
    ) {
      _id
      expiredAt
      paymentMethod
      total
      transactionNumber
      status
      checkoutUrl

      VAPaymentDetail
      paymentUrl
    }
  }
`;

export default withRouter(
  withI18n("student_bill_and_payment")(StudentBillAndPaymentNew)
);
